import React from 'react';
import * as styles from './landing.module.scss';
import Header from '@components/old-molecules/Header';
import Intro from '@components/old-molecules/landingSections/Intro';
import Analysis from '@components/old-molecules/landingSections/Analysis';
import BeforeGame from '@components/old-molecules/landingSections/BeforeGame';
import AfterGame from '@components/old-molecules/landingSections/AfterGame';
import Reviews from '@components/old-molecules/landingSections/Reviews';
import Join from '@components/old-molecules/landingSections/Join';
import Footer from '@components/old-molecules/Footer';

// TODO: Remove this page after Google Optimize redirect url test
const OldLandingLayout = ({ children }) => {
	return (
		<div className={styles.landingLayout}>
			<div className={styles.topMap}>
				<Header />
				<Intro />
				<Analysis />
				<BeforeGame />
			</div>
			<AfterGame />
			<div className={styles.bottomMap}>
				<Reviews />
				<Join />
			</div>
			<Footer />
		</div>
	);
};

export default OldLandingLayout;
