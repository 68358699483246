import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as styles from './beforeGame.module.scss';
import { setBeforeGameTab } from '@src/state/beforeGameTabs/actions';
import { useIntl } from 'gatsby-plugin-react-intl';
import SvgArrow from '@components/atoms/SvgArrow';
import { links } from '@src/constants';
import { sendEvent } from '@src/utils/amplitude';

const Buttons = ({ beforeGameTab, setBeforeGameTab }) => {
	const intl = useIntl();

	const tabBtnColor = {
		1: 'btn--black',
		2: 'btn--black',
		3: 'btn--white',
	};
	return (
		<div className={styles.btns}>
			<button
				className={`btn btn--sm ${tabBtnColor[beforeGameTab]}${beforeGameTab === 1 ? ' active' : ''}`}
				onClick={() => {
					sendEvent('New Landing / Before The Game / Change Screen', {
						State: 'Grenades',
					});
					setBeforeGameTab(1);
				}}
			>
				{intl.formatMessage({ id: 'landing.beforeGameBtns.btn1' })}
			</button>
			<button
				className={`btn btn--sm ${tabBtnColor[beforeGameTab]}${beforeGameTab === 2 ? ' active' : ''}`}
				onClick={() => {
					sendEvent('New Landing / Before The Game / Change Screen', {
						State: 'Teammates',
					});
					setBeforeGameTab(2);
				}}
			>
				{intl.formatMessage({ id: 'landing.beforeGameBtns.btn2' })}
			</button>
			<button
				className={`btn btn--sm ${tabBtnColor[beforeGameTab]}${beforeGameTab === 3 ? ' active' : ''}`}
				onClick={() => {
					sendEvent('New Landing / Before The Game / Change Screen', {
						State: 'Tactics',
					});
					setBeforeGameTab(3);
				}}
			>
				{intl.formatMessage({ id: 'landing.beforeGameBtns.btn3' })}
			</button>
		</div>
	);
};

const BeforeGame = ({ beforeGameTab, setBeforeGameTab }) => {
	const intl = useIntl();
	const [touchStart, setTouchStart] = useState(0);
	const [touchEnd, setTouchEnd] = useState(0);

	function handleTouchStart(e) {
		setTouchStart(e.targetTouches[0].clientX);
	}

	function handleTouchMove(e) {
		setTouchEnd(e.targetTouches[0].clientX);
	}

	function handleTouchEnd() {
		if (touchStart - touchEnd > 0) {
			if (beforeGameTab >= 3) {
				setBeforeGameTab(1);
			} else {
				setBeforeGameTab(beforeGameTab + 1);
			}
		}

		if (touchStart - touchEnd < 0) {
			if (beforeGameTab <= 1) {
				setBeforeGameTab(3);
			} else {
				setBeforeGameTab(beforeGameTab - 1);
			}
		}
	}

	return (
		<section id="beforeGame" className={styles.container}>
			<div
				className={styles.tabs}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			>
				<div className={`${styles.tab} ${styles.tab1} ${beforeGameTab === 1 ? styles.active : ''}`}>
					<div className={styles.btnsWrapper}>
						<span className={styles.btnsWrapperText}>{intl.formatMessage({ id: 'landing.beforeTitle' })}</span>
						<Buttons beforeGameTab={beforeGameTab} setBeforeGameTab={setBeforeGameTab} />
					</div>

					<div className={styles.tabInner}>
						<p className={styles.tabTitle}>{intl.formatMessage({ id: 'landing.beforeGameTabs.tab1.title' })}</p>
						<p className={styles.tabDescr}>
							{intl.formatMessage({ id: 'landing.beforeGameTabs.tab1.descr' })}
							<a
								href={links.grenades[intl.locale]}
								className={styles.tabLink}
								target="_blank"
								onClick={() => {
									sendEvent('New Landing / Before The Game / Click More', {
										State: 'Grenades',
									});
								}}
							>
								{intl.formatMessage({ id: 'landing.beforeGameTabs.link' })}{' '}
								<span className={styles.tabArrow}>
									<SvgArrow />
								</span>
							</a>
						</p>
					</div>
				</div>

				<div className={`${styles.tab} ${styles.tab2} ${beforeGameTab === 2 ? styles.active : ''}`}>
					<div className={styles.btnsWrapper}>
						<span className={styles.btnsWrapperText}>{intl.formatMessage({ id: 'landing.beforeTitle' })}</span>
						<Buttons beforeGameTab={beforeGameTab} setBeforeGameTab={setBeforeGameTab} />
					</div>

					<div className={styles.tabInner}>
						<p className={styles.tabTitle}>{intl.formatMessage({ id: 'landing.beforeGameTabs.tab2.title' })}</p>
						<p className={styles.tabDescr}>
							{intl.formatMessage({ id: 'landing.beforeGameTabs.tab2.descr' })}
							<a
								href={links.team[intl.locale]}
								className={styles.tabLink}
								target="_blank"
								onClick={() => {
									sendEvent('New Landing / Before The Game / Click More', {
										State: 'Teammates',
									});
								}}
							>
								{intl.formatMessage({ id: 'landing.beforeGameTabs.link' })}{' '}
								<span className={styles.tabArrow}>
									<SvgArrow />
								</span>
							</a>
						</p>
					</div>
				</div>

				<div className={`${styles.tab} ${styles.tab3} ${beforeGameTab === 3 ? styles.active : ''}`}>
					<div className={styles.btnsWrapper}>
						<span className={styles.btnsWrapperText}>{intl.formatMessage({ id: 'landing.beforeTitle' })}</span>
						<Buttons beforeGameTab={beforeGameTab} setBeforeGameTab={setBeforeGameTab} />
					</div>

					<div className={styles.tabInner}>
						<p className={styles.tabTitle}>{intl.formatMessage({ id: 'landing.beforeGameTabs.tab3.title' })}</p>
						<p className={styles.tabDescr}>
							{intl.formatMessage({ id: 'landing.beforeGameTabs.tab3.descr' })}
							<a
								href={links.strategy[intl.locale]}
								className={styles.tabLink}
								target="_blank"
								onClick={() => {
									sendEvent('New Landing / Before The Game / Click More', {
										State: 'Tactics',
									});
								}}
							>
								{intl.formatMessage({ id: 'landing.beforeGameTabs.link' })}{' '}
								<span className={styles.tabArrow}>
									<SvgArrow />
								</span>
							</a>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	beforeGameTab: state.beforeGameReducer.beforeGameTab,
});

const mapDispatchToProps = (dispatch) => ({
	setBeforeGameTab: (payload) => dispatch(setBeforeGameTab(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeforeGame);
