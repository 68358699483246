import React from 'react';
import * as styles from './mapPlayer.module.scss';

/**
 * @param {Object} props
 * @param {string} props.color one of ['green', 'blue']
 * @param {string} props.name 
 * @param {string} props.message 
 * @returns 
 */

const MapPlayer = (props) => {
  const {className, color = 'green', name, message} = props

  const colors = {
    green: '#C5FF7B',
    blue: '#8EE0E8'
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <span className={styles.icon}>
        <div className={styles.message} style={{color: colors[color], opacity: message ? 1 : 0}} data-message={message}>
          {message}
        </div>

        <svg className={styles.svg} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.72538 1.8321L6.5 0L8.27462 1.8321C11.0019 2.62938 13 5.21853 13 8.28947C13 11.9956 10.0899 15 6.5 15C2.91015 15 0 11.9956 0 8.28947C0 5.21853 1.99811 2.62938 4.72538 1.8321ZM11.4706 8.28947C11.4706 11.1236 9.24518 13.4211 6.5 13.4211C3.75482 13.4211 1.52941 11.1236 1.52941 8.28947C1.52941 5.45538 3.75482 3.15789 6.5 3.15789C9.24518 3.15789 11.4706 5.45538 11.4706 8.28947Z" fill={colors[color]}/>
        </svg>
      </span>
      <span className={styles.name}>{name}</span>
    </div>
  )
};

export default MapPlayer;
