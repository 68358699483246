import React, { useEffect, useState } from 'react';
import * as styles from './intro.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import useResponsive from '@src/utils/useResponsive';
import MapPlayer from '@components/molecules/MapPlayer';
import arrowRight from '@src/assets/images/landing/arrowRightInCircle.svg';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import PlayerSearch from '../../PlayerSearch/PlayerSearch';

const Intro = () => {
	const intl = useIntl();
	const [isDesktop, isTablet, isMobile] = useResponsive();
	const [player1Msg, setPlayer1Msg] = useState('Enemy spotted');
	const [player2Msg, setPlayer2Msg] = useState('');

	useEffect(() => {
		const player1Interval = setInterval(() => {
			setPlayer1Msg((msg) => {
				if (msg) return '';
				return 'Enemy spotted';
			});
		}, 3500);

		const player2Interval = setInterval(() => {
			setPlayer2Msg((msg) => {
				if (msg) return '';
				return 'Taking fire, need assistance!';
			});
		}, 3000);

		return () => {
			clearInterval(player1Interval);
			clearInterval(player2Interval);
		};
	}, []);

	return (
		<section className={styles.container}>
			<MapPlayer color="green" name="Bromine" message={player1Msg} className={styles.mapPlayer1} />
			<MapPlayer color="blue" name="innersh1ne" message={player2Msg} className={styles.mapPlayer2} />
			<MapPlayer color="green" name="Insulin" className={styles.mapPlayer3} />
			<div className={styles.inner}>
				<h1 id="about" className={styles.title}>
					<span className={styles.titleTop}>{intl.formatMessage({ id: 'landing.intro.titleTop' })}</span>
					<span className={styles.titleMid}>CS2</span>
					<span className={styles.titleBottom}>{intl.formatMessage({ id: 'landing.intro.titleBottom' })}</span>
				</h1>
				<ul className={styles.list}>
					<li className={styles.listItem}>
						<h2>1 000 000 +</h2>
						<p className={styles.listItemDescr}>{intl.formatMessage({ id: 'landing.intro.analyzed' })}</p>
					</li>
					<li className={styles.listItem}>
						<h2>50 000 +</h2>
						<p className={styles.listItemDescr}>{intl.formatMessage({ id: 'landing.intro.changes' })}</p>
					</li>
				</ul>
			</div>
			{/* <div>
				<PlayerSearch />
			</div> */}
			{!isMobile ? (
				<div className={styles.inner}>
					<AnchorLink href="#analysis" className={styles.scroll}>
						<img src={arrowRight} className={styles.scrollIcon} />
						<div className={styles.scrollText}>
							<span className={styles.scrollTextTop}>{intl.formatMessage({ id: 'landing.intro.scroll' })}</span>
							<span className={styles.scrollTextBottom}>{intl.formatMessage({ id: 'landing.intro.scrollText' })}</span>
						</div>
					</AnchorLink>
				</div>
			) : null}
		</section>
	);
};

export default Intro;
