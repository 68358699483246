const dict = {
	en: `
    {
      "@context": "http://schema.org",
      "@type": "SoftwareApplication",
      "name": "SCOPE.GG - CS2 Stats Tracker",
      "description": "Keep track of your statistics and progress in CS2. Check match demos, grenades prediction, compare yourself with other players and get better with Scope.gg service.",
      "operatingSystem": "Windows, Mac, Linux, Web",
      "applicationCategory": "Game Analytics",
      "url": "https://scope.gg/",
      "image": "https://scope.gg/guides/content/images/size/w600/2022/04/scopegg-logo-embed.png",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "117"
      },
      "mainEntity": {
        "@type": "FAQPage",
        "mainEntityOfPage": "https://scope.gg/",
        "headline": "Scope Frequently Asked Questions",
        "description": "Here are some common questions about Scope and their answers.",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is Scope?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Scope is an esports data analytics and visualization platform that helps gamers and fans track, analyze, and improve their performance."
            }
          },
          {
            "@type": "Question",
            "name": "What operating systems does Scope support?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Scope supports Windows, Mac, Linux, and Web operating systems."
            }
          },
          {
            "@type": "Question",
            "name": "What types of analytics does Scope provide?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Scope provides various analytics, including player performance tracking, team performance tracking, map and round analytics, and more."
            }
          }
        ]
      }
    }
  `,
	ru: `
    {
      "@context": "http://schema.org",
      "@type": "SoftwareApplication",
      "name": "SCOPE.GG - CS2 Stats Tracker",
      "description": "Отслеживай свою статистику и прогресс в CS2. Смотри демки, изучай гранаты с помощью предиктора, сравнивай себя с другими игроками и прокачивай свой скилл вместе со SCOPE.GG ",
      "operatingSystem": "Windows, Mac, Linux",
      "applicationCategory": "Игровая Аналитика",
      "url": "https://scope.gg/ru/",
      "image": "https://scope.gg/guides/content/images/size/w600/2022/04/scopegg-logo-embed.png",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "124"
      },
      "mainEntity": {
        "@type": "FAQPage",
        "mainEntityOfPage": "https://scope.gg/ru/",
        "headline": "Часто задаваемые вопросы о Scope",
        "description": "Вот некоторые общие вопросы о Scope и их ответы.",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "Что такое Scope?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Scope - это аналитическая платформа для электронных спортов, которая помогает игрокам и командам улучшать свою игру и достигать лучших результатов."
            }
          },
          {
            "@type": "Question",
            "name": "Какие операционные системы поддерживает Scope?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Scope поддерживает операционные системы Windows, Mac и Linux."
            }
          },
          {
            "@type": "Question",
            "name": "Какие аналитические возможности предоставляет Scope?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Scope предоставляет различные аналитические функции, включая отслеживание производительности игроков и команд, анализ карт и раундов, и многое другое."
            }
          }
        ]
      }
    }`,
};

export function getMicromarkingScriptByLocale(locale) {
	switch (locale) {
		case 'en':
			return dict.en;
		case 'ru':
			return dict.ru;
		default:
			break;
	}
}
