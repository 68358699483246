import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './CTA.module.scss';

import LoginActions from '@src/components/molecules/LoginActions';
import MapPlayer from '@components/molecules/MapPlayer';

const CTA = () => {
	const intl = useIntl();
	const [playerMessage, setPlayerMessage] = useState('');
	const [player2Message, setPlayer2Message] = useState('');

	useEffect(() => {
		const interval = setInterval(() => {
			setPlayerMessage((msg) => {
				if (msg) return '';
				return 'Taking fire, need assistance!';
			});
		}, 5000);

		const interval2 = setInterval(() => {
			setPlayer2Message((msg) => {
				if (msg) return '';
				return 'Enemy spotted!';
			});
		}, 3000);

		return () => {
			clearInterval(interval)
			clearInterval(interval2)
		};
	}, []);

	return (
		<section id="analysis" className={styles.container}>
			<div className={styles.content}>
				<h2 className={styles.title}>
					{intl.locale === 'ru'
						? 'Всё в одном месте для твоего развития в CS2'
						: 'Everything in one place for your skill boost in CS2'}
				</h2>
				<div className={styles.actions}>
					<LoginActions location="Prebottom" />
				</div>
			</div>

			<MapPlayer className={styles.mapPlayer1} name="sidekrown" color="blue" />
			<MapPlayer className={styles.mapPlayer2} name="neudemonia" color="green" />
			<MapPlayer className={styles.mapPlayer3} name="thayer4reina" color="blue" message={playerMessage} />
			<MapPlayer className={styles.mapPlayer4} name="Insulin" color="green" />
			<MapPlayer className={styles.mapPlayer5} name="Bromine" color="green" message={player2Message}/>
		</section>
	);
};

export default CTA;
