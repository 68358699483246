import React, { forwardRef, useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { sendEvent } from '@src/utils/amplitude';
import * as styles from './videoBlockInfo.module.scss';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const VideoBlockInfo = forwardRef(
	(
		{
			name,
			title,
			description,
			className,
			style,
			videoMp4 = '',
			videoWebm = '',
			videoPoster = '',
			isHighlights = false,
			onTryBtnClick,
			tryBtnEventLocation,
		},
		ref
	) => {
		const account = useSelector((state) => state.authReducer);
		const [showVideoEl, setShowVideoEl] = useState(false);
		const intl = useIntl();

		useEffect(() => {
			if (videoMp4 || videoWebm) setShowVideoEl(true);
		}, [videoMp4, videoWebm]);

		return (
			<div className={[styles.container, className].join(' ')} style={style} ref={ref}>
				<span className={[styles.name, 'btn btn--blue btn--sm'].join(' ')}>{name}</span>
				<h2 className={styles.title}>{title}</h2>
				<p className={styles.descr}>{description}</p>
				<button
					className={styles.tryBtn}
					onClick={() => {
						onTryBtnClick && onTryBtnClick();
						sendEvent('New Landing / Click Try button', {
							Location: tryBtnEventLocation,
						});
						mixPanelService.sendEvent({
							event: 'Landing / Click Try Button',
							properties: {
								Location: tryBtnEventLocation,
							}
						}, account?.userData?.steamAccountId || null)
					}}
				>
					{intl.locale === 'ru' ? 'попробовать' : 'Try now for free'}
				</button>

				{showVideoEl && (
					<div className={styles.video}>
						{!isHighlights && (
							<video controls poster={videoPoster}>
								{videoWebm && <source src={videoWebm} type="video/webm" />}
								{videoMp4 && <source src={videoMp4} type="video/mp4" />}
							</video>
						)}
						{isHighlights && (
							<>
								<iframe
									width="523"
									height="367"
									src="https://www.youtube-nocookie.com/embed/BuoQY8_Z1pY?controls=0&loop=1&autoplay=1&mute=1&vq=hd720&enablejsapi=1&fs=0&loop=1&modestbranding=1&playlist=BuoQY8_Z1pY&playsinline=1&rel=0"
									title="Scope.gg Highlights"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope"
									allowFullScreen
								/>
								{/*<div className={styles.highlights}>
									<div className={styles.highlights_type}>Clip of the day</div>
									<div className={styles.highlights_title}>3K on Overpass</div>
									<div className={styles.highlights_player}>
										<img src="" alt="Player" />
										Insulin: scope.gg
									</div>
								</div>*/}
							</>
						)}
					</div>
				)}
			</div>
		);
	}
);

export default VideoBlockInfo;
