import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { connect } from 'react-redux';
import { setBeforeGameTab } from '@src/state/beforeGameTabs/actions';
import { setAfterGameTab } from '@src/state/afterGameTabs/actions';
import { useIntl } from 'gatsby-plugin-react-intl';
import MapPlayer from '@components/molecules/MapPlayer';
import { sendEvent } from '@src/utils/amplitude';
import * as styles from './analysis.module.scss';

const Tabs = ({ setBeforeGameTab, setAfterGameTab }) => {
	const [activeTab, setActiveTab] = useState(1);
	const intl = useIntl();

	return (
		<div className={styles.tabs}>
			<div className={`${styles.tabsHead} ${styles.tabsGrid}`}>
				<button
					className={`
						${styles.toggleBtn} 
						${activeTab === 1 ? styles.active : ''}
					`}
					onClick={() => setActiveTab(1)}
				>
					<span className={styles.tabsHeadTitle}>
						{intl.formatMessage({ id: 'landing.beforeTitle' })}
					</span>
					<span className={`icon icon--long-arrow ${styles.arrow}`} />
				</button>
				<button
					className={`
						${styles.toggleBtn}
						${activeTab === 2 ? styles.active : ''}
					`}
					onClick={() => setActiveTab(2)}
				>
					<span className={styles.tabsHeadTitle}>{intl.formatMessage({ id: 'landing.afterTitle' })}</span>
					<span className={`icon icon--long-arrow ${styles.arrow}`} />
				</button>
			</div>

			<div className={`${styles.tabsList} ${styles.tabsGrid}`}>
				<div
					className={`
						${styles.tab}
						${activeTab === 1 ? styles.active : ''}
					`}
				>
					<div className={styles.tabLinks}>
						<AnchorLink
							href="#beforeGame"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'Grenades',
								});
								setBeforeGameTab(1);
							}}
						>
							{intl.formatMessage({ id: 'landing.beforeGameBtns.btn1' })}
						</AnchorLink>
						<AnchorLink
							href="#beforeGame"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'Teammates',
								});
								setBeforeGameTab(2);
							}}
						>
							{intl.formatMessage({ id: 'landing.beforeGameBtns.btn2' })}
						</AnchorLink>
						<AnchorLink
							href="#beforeGame"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'DevelopTactics',
								});
								setBeforeGameTab(3);
							}}
						>
							{intl.formatMessage({ id: 'landing.beforeGameBtns.btn3' })}
						</AnchorLink>
					</div>
					<p className={styles.tabText}>
						<a href="https://scope.gg/" target="_blank">
							Scope.gg{' '}
						</a>
						{intl.formatMessage({ id: 'landing.analysis.beforeText' })}
					</p>
				</div>
				<div
					className={`
						${styles.tab}
						${activeTab === 2 ? styles.active : ''}
					`}
				>
					<div className={styles.tabLinks}>
						<AnchorLink
							href="#analizy"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'Mistakes',
								});
							}}
						>
							{intl.formatMessage({ id: 'landing.afterGameBtns.btn1' })}
						</AnchorLink>
						<AnchorLink
							href="#strengths"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'Economics',
								});
							}}
							style={{maxWidth: 310}}
						>
							{intl.formatMessage({ id: 'landing.afterGameBtns.btn2' })}
						</AnchorLink>
						{/* <AnchorLink
							href="#strengths"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'Positioning',
								});
							}}
						>
							{intl.formatMessage({ id: 'landing.afterGameBtns.btn3' })}
						</AnchorLink>
						<AnchorLink
							href="#strengths"
							className="btn btn--light"
							onClick={() => {
								sendEvent('New Landing / Anchor Click', {
									Anchor: 'AIM',
								});
							}}
						>
							{intl.formatMessage({ id: 'landing.afterGameBtns.btn4' })}
						</AnchorLink> */}
					</div>
					<p className={styles.tabText}>
						<a href="https://scope.gg/" target="_blank">
							Scope.gg{' '}
						</a>
						{intl.formatMessage({ id: 'landing.analysis.afterText' })}
					</p>
				</div>
			</div>
		</div>
	);
};

const Analysis = ({ setBeforeGameTab, setAfterGameTab }) => {
	const intl = useIntl();
	const [playerMessage, setPlayerMessage] = useState('');

	useEffect(() => {
		const interval = setInterval(() => {
			setPlayerMessage((msg) => {
				if (msg) return '';
				return 'Taking fire, need assistance!';
			});
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	return (
		<section id="analysis" className={styles.container}>
			<div className={styles.text}>
				<h2 className={styles.title}>
					<span className={styles.titleTop}>{intl.formatMessage({ id: 'landing.analysis.titleTop' })}</span>
					<span>{intl.formatMessage({ id: 'landing.analysis.titleBottom' })}</span>
				</h2>

				<p className={styles.descr}>
					{intl.formatMessage({ id: 'landing.analysis.descrBegin' })}{' '}
					<span>{intl.formatMessage({ id: 'landing.analysis.descrBlue' })}</span>.{' '}
					{intl.formatMessage({ id: 'landing.analysis.descrEnd' })}.
				</p>

				<div className={styles.players}>
					<MapPlayer className={styles.mapPlayer1} name="sidekrown" color="blue" />
					<MapPlayer className={styles.mapPlayer2} name="neudemonia" color="green" />
					<MapPlayer className={styles.mapPlayer3} name="thayer4reina" color="blue" message={playerMessage} />
					<MapPlayer className={styles.mapPlayer4} name="Insulin" color="green" />
				</div>
			</div>

			<div>
				<Tabs setBeforeGameTab={setBeforeGameTab} setAfterGameTab={setAfterGameTab} />
			</div>
		</section>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setBeforeGameTab: (payload) => dispatch(setBeforeGameTab(payload)),
	setAfterGameTab: (payload) => dispatch(setAfterGameTab(payload)),
});

export default connect(null, mapDispatchToProps)(Analysis);
