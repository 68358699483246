import React, { useEffect, useState } from 'react';
import * as styles from './header.module.scss';
import useResponsive from '@src/utils/useResponsive';
import Logo from '@components/molecules/Logo';
import MenuList from '@components/molecules//MenuList';
import SideActions from '@components/molecules//SideActions';

import XLogo from '@src/assets/images/landing/Xplay.svg';
import burger from '@src/assets/images/landing/burger.svg';
import crossIcon from '@src/assets/images/landing/cross-icon.svg';

const Header = () => {
	const [isDesktop, isTablet, isMobile] = useResponsive();
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	useEffect(() => {
		if (showMobileMenu) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'initial';
		}
	}, [showMobileMenu]);

	return (
		<header className={styles.container}>
			{!isTablet ? (
				<div className={styles.logosContainer}>
					<Logo />
					{!isMobile ? 'x' : ''}
					<a href="https://excorp.gg" target="_blank">
						<img src={XLogo} alt="EXCORP.GG LOGO" />
					</a>
				</div>
			) : (
				<Logo size="small" />
			)}

			{!isMobile ? (
				<>
					<MenuList />
					<SideActions />
				</>
			) : (
				<>
					<button onClick={() => setShowMobileMenu(true)} style={{ width: '36px', height: '36px', marginLeft: 'auto' }}>
						<img src={burger} alt="Burger menu" />
					</button>
				</>
			)}

			{isMobile ? (
				<aside className={`${styles.mobileMenu}${showMobileMenu ? ' open' : ''}`}>
					<button className={styles.mobileMenuClose} onClick={() => setShowMobileMenu(false)}>
						<img src={crossIcon} alt="SCOPE.GG Cross Icon" />
					</button>
					<div className={styles.logosContainer}>
						<Logo />
						<a href="https://excorp.gg" target="_blank">
							<img src={XLogo} alt="EXCORP.GG LOGO" />
						</a>
					</div>
					<MenuList />
					<SideActions />
				</aside>
			) : null}
		</header>
	);
};

export default Header;
