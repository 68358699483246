import React, { useEffect, useRef, useState } from 'react';
import * as styles from './afterGame.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useKey } from 'react-use';

import Analizy from './components/Analizy';
import Strengths from './components/Strengths';

import star from '@src/assets/images/landing/star.svg';
import { connect } from 'react-redux';
import { sendEvent } from '@src/utils/amplitude';

const AfterGame = ({ afterGameTab }) => {
	const intl = useIntl();
	const [flashCounter, setFlashCounter] = useState(3);
	const [showFlash, setShowFlash] = useState(false);
	const [showFlashContent, setShowFlashContent] = useState(false);
	const interval = useRef(null);
	const flashed = useRef(false);

	function flashing() {
		if (flashed.current) return;
		setShowFlash(true);

		setTimeout(() => {
			setShowFlashContent(() => true);

			interval.current = setInterval(() => {
				setFlashCounter((flashCounter) => {
					if (flashCounter == 0) {
						clearInterval(interval.current);
						setShowFlashContent(false);
						setShowFlash(false);
						return 3;
					}

					return flashCounter - 1;
				});
			}, 1000);
		}, 500);
	}

	useKey('f', flashing);
	useKey('а', flashing);

	useEffect(() => {
		flashed.current = showFlash;
	}, [showFlash]);

	useEffect(() => {
		if (showFlash) {
			document.body.style.overflow = 'hidden';
			sendEvent('New Landing / Flashbang Activate');
		} else {
			document.body.style.overflow = 'initial';
		}
	}, [showFlash]);

	return (
		<section id="afterGame" className={styles.container}>
			<div className={`${styles.flash} ${showFlash ? styles.open : ''}`}>
				<div className={`${styles.flashContent} ${showFlashContent ? styles.show : ''}`}>
					<div className={styles.flashMain}>
						<img src={star} alt="Star" />
						<p className={styles.flashText}>
							{intl.formatMessage({ id: 'landing.afterGameTabs.flash.titleTop' })}
							<span>{intl.formatMessage({ id: 'landing.afterGameTabs.flash.titleBottom' })}</span>
						</p>
					</div>
					<div className={styles.flashTimer}>
						<span className={styles.flashCounter}>{flashCounter}</span>
						{intl.formatMessage({ id: 'landing.afterGameTabs.flash.tracking' })}
					</div>
				</div>
			</div>
			<div>
				<Analizy onFKeyClick={flashing}/>
				<Strengths />
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	afterGameTab: state.afterGameReducer.afterGameTab,
});

export default connect(mapStateToProps)(AfterGame);
