import React from 'react';
import styles from './analizy.module.scss';
import Buttons from '../Buttons';
import { useIntl } from 'gatsby-plugin-react-intl';

const Analizy = ({ onFKeyClick }) => {
	const intl = useIntl();
	return (
		<div id="analizy" className={styles.wrapper}>
			<Buttons afterGameTab={1} />
			<div className={styles.inner}>
				<p className={styles.text}>
					{intl.formatMessage({ id: 'landing.afterGameTabs.tab1.titleTop' })}
					<span>{intl.formatMessage({ id: 'landing.afterGameTabs.tab1.titleBottom' })}</span>
				</p>

				<div className={styles.flashBtn}>
					<button className={styles.fKey} onClick={() => onFKeyClick()} />
					<p className={styles.flashBtnText}>
						{intl.formatMessage({ id: 'landing.afterGameTabs.tab1.flashbang' })}
						<span className={styles.flashBtnArrow} />
					</p>
					<p className={styles.flashBtnWarning}>{intl.formatMessage({ id: 'landing.afterGameTabs.tab1.flashHint' })}</p>
				</div>
			</div>
		</div>
	);
};

export default Analizy;
