import React from 'react';
import * as styles from './contacts.module.scss';

const Contacts = () => {
	return (
		<div className={styles.links}>
			<a href="mailto:partnership@scope.gg" className={`${styles.mailLink} ${styles.linksItem}`}>
				partnership@scope.gg
			</a>
			<a href="mailto:support@scope.gg" className={`${styles.mailLink} ${styles.linksItem}`}>
				support@scope.gg
			</a>
		</div>
	);
};

export default Contacts;
