import React from 'react';
import * as styles from './join.module.scss';
import LoginActions from '@components/molecules/LoginActions';
import { useIntl } from 'gatsby-plugin-react-intl';


const Join = () => {
	const intl = useIntl();

	return (
		<section className={styles.container}>
			<h2 className={styles.title}>
				<span>{intl.formatMessage({ id: 'landing.join.titleTop' })}</span>
				{intl.formatMessage({ id: 'landing.join.titleBottom' })}
			</h2>
			<ul className={styles.steps}>
				<li className={styles.step}>
					<div className={styles.stepHead}>
						<span className={styles.stepOrder}>A</span>
						step
						<span className={`icon--long-arrow ${styles.stepArrow}`} />
					</div>
					<h3 className={styles.stepTitle}>{intl.formatMessage({ id: 'landing.join.steps.A.title' })}</h3>
					<p className={styles.stepDescr}>{intl.formatMessage({ id: 'landing.join.steps.A.text' })}</p>
					<div className={styles.actions}>
						<LoginActions location="Header" />
					</div>
				</li>
				<li className={styles.step}>
					<div className={styles.stepHead}>
						<span className={styles.stepOrder}>B</span>
						step
						<span className={`icon--long-arrow ${styles.stepArrow}`} />
					</div>
					<h3 className={styles.stepTitle}>{intl.formatMessage({ id: 'landing.join.steps.B.title' })}</h3>
					<p className={styles.stepDescr}>{intl.formatMessage({ id: 'landing.join.steps.B.text' })}</p>
				</li>
				<li className={styles.step}>
					<div className={styles.stepHead}>
						<span className={styles.stepOrder}>C</span>
						step
						<span className={`icon--long-arrow ${styles.stepArrow}`} />
					</div>
					<h3 className={styles.stepTitle}>{intl.formatMessage({ id: 'landing.join.steps.C.title' })}</h3>
					<p className={styles.stepDescr}>{intl.formatMessage({ id: 'landing.join.steps.C.text' })}</p>
				</li>
			</ul>
		</section>
	);
};

export default Join;
