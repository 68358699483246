import React from 'react';
import * as styles from './reviews.module.scss';
import { useIntl } from 'gatsby-plugin-react-intl';
import SvgArrow from '@components/atoms/SvgArrow';
import authorImg from './assets/JanMuller.png';
import esports from './assets/esports.png';
import dexerto from './assets/dexerto.png';
import cybersport from './assets/cybersport.png';
import breakflip from './assets/breakflip.png';

const links = {
	esports: 'https://www.esports.com/de/csgo-funfacts-die-ihr-noch-nicht-kanntet-83102',
	dexerto: 'https://www.dexerto.com/csgo/alarming-number-of-chickens-killed-during-esl-pro-league-csgo-finals-1298045/',
	cybersport: 'https://www.cybersport.ru/tags/cs-go/electronic-bolee-6-3-tys-raz-rassmatrival-oruzhie-na-iem-beijing-online-2020',
	breakflip: 'https://www.breakflip.com/fr/cs-go/guide/csgo-comment-lancer-des-smokes-grenades-et-fumigenes-astuces-et-reperes-15682',
}

const Reviews = () => {
	const intl = useIntl()

	return (
		<section className={styles.container}>
			<div className={styles.review}>
				<p className={styles.text}>
					<span className={styles.textFiller}></span> “
					<a href="https://scope.gg" target="_blank">
						scope.gg
					</a>{' '}
					{intl.formatMessage({ id: 'landing.review.textBeforeArrow' })} <span className={styles.arrow}>{<SvgArrow />}</span> {intl.formatMessage({ id: 'landing.review.textAfterArrow' })}”.
				</p>

				<div className={styles.author}>
					<img src={authorImg} alt="Jan “Swani” Muller" className={styles.authorImg} />
					<span className={styles.authorFrom}>{intl.formatMessage({ id: 'landing.review.authorFrom' })}</span>
					<span className={styles.authorName}>Jan “Swani” Muller</span>
				</div>
			</div>

			<div className={styles.media}>
				<span className={styles.mediaText}>{intl.formatMessage({ id: 'landing.review.theTalk' })}:</span>
				<ul className={styles.mediaList}>
					<li className={styles.mediaItem}>
						<a href={links.esports} className={styles.mediaLink} target="_blank">
							<img src={esports} alt="Esports.com logo" />
						</a>
					</li>
					<li className={styles.mediaItem}>
						<a href={links.dexerto} className={styles.mediaLink} target="_blank">
							<img src={dexerto} alt="Dexerto.com logo" />
						</a>
					</li>
					<li className={styles.mediaItem}>
						<a href={links.cybersport} className={styles.mediaLink} target="_blank">
							<img src={cybersport} alt="Cybersport.ru logo" />
						</a>
					</li>
					<li className={styles.mediaItem}>
						<a href={links.breakflip} className={styles.mediaLink} target="_blank">
							<img src={breakflip} alt="Breakflip.com logo" />
						</a>
					</li>
				</ul>
			</div>
		</section>
	);
};

export default Reviews;
