import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as styles from './presentation.module.scss';
import * as stylesHighlights from '../../VideoBlockInfo/videoBlockInfo.module.scss';
import { connect } from 'react-redux';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useInView } from 'react-intersection-observer';
import useResponsive from '@src/utils/useResponsive';

import VideoBlockInfo from '@components/molecules/VideoBlockInfo';

const transPathStart = 'videolanding.presentation.';

const intersectionObserverOptions = {
	threshold: 1,
};

const Presentation = ({ setLoginPopupOpen }) => {
	const intl = useIntl();
	const [isDesktop, isTablet, isMobile] = useResponsive();

	const videosFolderPath = intl.locale === 'ru' ? '/video/presentation/ru/' : '/video/presentation/en/';

	const [showVideo, setShowVideo] = useState(true);
	const [videoPath, setVideoPath] = useState(videosFolderPath + 'Dashboard.webm');

	const [lastInfoBlockPaddingBottom, setLastInfoBlockPaddingBottom] = useState(0);
	const [videoTopCoord, setVideoTopCoord] = useState(100);

	const videoRef = useRef();

	const [dashboardRef, dashboardInView] = useInView(intersectionObserverOptions);
	const [highlightsRef, highlightsInView] = useInView(intersectionObserverOptions);
	const [grenadesRef, grenadesInView] = useInView(intersectionObserverOptions);
	const [replayRef, replayInView] = useInView(intersectionObserverOptions);
	const [performanceRef, performanceInView] = useInView(intersectionObserverOptions);
	const [comparisonRef, comparisonInView] = useInView(intersectionObserverOptions);
	const [prematchRef, prematchInView] = useInView(intersectionObserverOptions);
	const [overviewRef, overviewInView, overviewEntry] = useInView(intersectionObserverOptions);

	const handleTryBtnClick = useCallback(() => {
		setLoginPopupOpen(true);
	});

	const toggleVideoPath = (path) => {
		if (path === videoPath) return;
		setShowVideo(false);
		setTimeout(() => {
			setVideoPath('');
			setVideoPath(path);
			setShowVideo(true);
		}, 300);
	};

	useEffect(() => {
		if (isMobile) return;
		if (lastInfoBlockPaddingBottom !== 0) return;

		if (overviewEntry) {
			const videoElHeight = videoRef.current.offsetHeight;
			const lastInfoBlockElHeight = overviewEntry.target.offsetHeight;

			if (videoElHeight > lastInfoBlockElHeight) {
				setLastInfoBlockPaddingBottom(videoElHeight - lastInfoBlockElHeight);
			}
		}
	}, [overviewEntry]);

	useEffect(() => {
		if (isMobile) return;
		const windowHeight = window.innerHeight;
		const videoHeight = videoRef.current.offsetHeight;

		if (videoHeight < windowHeight) setVideoTopCoord((windowHeight - videoHeight) / 2);
	}, [videoRef.current]);

	useEffect(() => {
		if (dashboardInView) toggleVideoPath(videosFolderPath + 'Dashboard.webm');
		if (highlightsInView) toggleVideoPath('highlights');
		if (grenadesInView) toggleVideoPath(videosFolderPath + 'Granade.webm');
		if (replayInView) toggleVideoPath(videosFolderPath + 'Replay.webm');
		if (performanceInView) toggleVideoPath(videosFolderPath + 'Perfomance.webm');
		if (comparisonInView) toggleVideoPath(videosFolderPath + 'Sravnenie.webm');
		if (prematchInView) toggleVideoPath(videosFolderPath + 'Prematch.webm');
		if (overviewInView) toggleVideoPath(videosFolderPath + 'MyMatches.webm');
	}, [
		dashboardInView,
		highlightsInView,
		grenadesInView,
		replayInView,
		performanceInView,
		comparisonInView,
		prematchInView,
		overviewInView,
	]);

	return (
		<section id="presentation" className={styles.container}>
			<div className={[styles.inner, 'container-v'].join(' ')}>
				<div className={styles.info}>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'dashboard.name' })}
						title={intl.formatMessage({ id: transPathStart + 'dashboard.title' })}
						description={intl.formatMessage({ id: transPathStart + 'dashboard.description' })}
						className={styles.videoBlockInfo}
						ref={dashboardRef}
						videoWebm={isMobile ? videosFolderPath + 'Dashboard.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Dashboard.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Dashboard.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="1 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'highlights.name' })}
						title={intl.formatMessage({ id: transPathStart + 'highlights.title' })}
						description={intl.formatMessage({ id: transPathStart + 'highlights.description' })}
						className={styles.videoBlockInfo}
						ref={highlightsRef}
						videoWebm={isMobile ? videosFolderPath + 'Highlights.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Highlights.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Highlights.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="2 screen"
						isHighlights={true}
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'grenades.name' })}
						title={intl.formatMessage({ id: transPathStart + 'grenades.title' })}
						description={intl.formatMessage({ id: transPathStart + 'grenades.description' })}
						className={styles.videoBlockInfo}
						ref={grenadesRef}
						videoWebm={isMobile ? videosFolderPath + 'Granade.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Granade.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Granade.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="3 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'replay.name' })}
						title={intl.formatMessage({ id: transPathStart + 'replay.title' })}
						description={intl.formatMessage({ id: transPathStart + 'replay.description' })}
						className={styles.videoBlockInfo}
						ref={replayRef}
						videoWebm={isMobile ? videosFolderPath + 'Replay.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Replay.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Replay.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="4 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'performance.name' })}
						title={intl.formatMessage({ id: transPathStart + 'performance.title' })}
						description={intl.formatMessage({ id: transPathStart + 'performance.description' })}
						className={styles.videoBlockInfo}
						ref={performanceRef}
						videoWebm={isMobile ? videosFolderPath + 'Perfomance.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Perfomance.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Perfomance.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="5 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'comparison.name' })}
						title={intl.formatMessage({ id: transPathStart + 'comparison.title' })}
						description={intl.formatMessage({ id: transPathStart + 'comparison.description' })}
						className={styles.videoBlockInfo}
						ref={comparisonRef}
						videoWebm={isMobile ? videosFolderPath + 'Sravnenie.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Sravnenie.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Sravnenie.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="6 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'prematch.name' })}
						title={intl.formatMessage({ id: transPathStart + 'prematch.title' })}
						description={intl.formatMessage({ id: transPathStart + 'prematch.description' })}
						className={styles.videoBlockInfo}
						ref={prematchRef}
						videoWebm={isMobile ? videosFolderPath + 'Prematch.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/Prematch.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/Prematch.jpg'}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="7 screen"
					/>
					<VideoBlockInfo
						name={intl.formatMessage({ id: transPathStart + 'overview.name' })}
						title={intl.formatMessage({ id: transPathStart + 'overview.title' })}
						description={intl.formatMessage({ id: transPathStart + 'overview.description' })}
						className={styles.videoBlockInfo}
						ref={overviewRef}
						videoWebm={isMobile ? videosFolderPath + 'MyMatches.webm' : ''}
						videoMp4={isMobile ? videosFolderPath + 'mp4/MyMatches.mp4' : ''}
						videoPoster={videosFolderPath + 'posters/MyMatches.jpg'}
						style={{ paddingBottom: isMobile ? 0 : lastInfoBlockPaddingBottom }}
						onTryBtnClick={handleTryBtnClick}
						tryBtnEventLocation="8 screen"
					/>
				</div>
				{!isMobile && (
					<div className={styles.blockVideo}>
						{videoPath === 'highlights' ? (
							<>
								<iframe
									ref={videoRef}
									className={styles.video}
									style={{ opacity: showVideo ? 1 : 0, top: videoTopCoord }}
									width="1020"
									height="573"
									src="https://www.youtube-nocookie.com/embed/BuoQY8_Z1pY?controls=0&loop=1&autoplay=1&mute=1&vq=hd720&enablejsapi=1&fs=0&loop=1&modestbranding=1&playlist=BuoQY8_Z1pY&playsinline=1&rel=0"
									title="Scope.gg Highlights"
									frameBorder="0"
									allow="accelerometer; autoplay; encrypted-media; gyroscope"
									allowFullScreen
								/>
								{/*<div className={stylesHighlights.highlights}>
									<div className={stylesHighlights.highlights_type}>Clip of the day</div>
									<div className={stylesHighlights.highlights_title}>3K on Overpass</div>
									<div className={stylesHighlights.highlights_player}>
										<img src="" alt="Player" />
										Insulin: scope.gg
									</div>
								</div>*/}
							</>
						) : (
							<video
								src={videoPath}
								className={styles.video}
								style={{ opacity: showVideo ? 1 : 0, top: videoTopCoord }}
								loop
								muted
								autoPlay
								ref={videoRef}
							/>
						)}
						<div style={{height: '100%'}}></div>
					</div>
				)}
			</div>
		</section>
	);
};

const mapDispatchToProps = (dispatch) => ({
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(null, mapDispatchToProps)(Presentation);
