import React, { useState } from 'react';
import styles from './strengths.module.scss';
import Buttons from '../Buttons';
import ArrowRight from '@src/assets/images/landing/arrowRightInCircle.svg';
import { useIntl } from 'gatsby-plugin-react-intl';

const Strengths = () => {
	const intl = useIntl();
	const [hovered, setHovered] = useState(false);

	return (
		<div id="strengths" className={styles.content}>
			<div className={styles.topRightDecor} />
			<div className={styles.bottomRightDecor} />
			<div className={styles.bottomLeftDecor} />
			<div className={`${styles.column} ${styles.column1}`} />
			<div className={`${styles.column} ${styles.column2}`} />
			<div className={`${styles.circle} ${hovered ? styles.hovered : ''}`} />

			<div className={styles.wrapper}>
				<Buttons afterGameTab={2} />
				<div className={styles.inner}>
					<p className={styles.text}>
						{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.titleTop' })}
						<span>{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.titleBottom' })}</span>
					</p>

					<div className={styles.rightSide} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
						<div className={`${styles.hint} ${hovered ? styles.hovered : ''}`}>
							<img src={ArrowRight} alt="#" className={styles.hintArrow} />
							<span>
								<b className={styles.cursor}>{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.cursor' })}</b>,
								<br />
								{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.discover' })}
							</span>
						</div>

						<div className={`${styles.results} ${hovered ? styles.hovered : ''}`}>
							<div className={styles.zoneName}>{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.zoneName' })}</div>
							<div className={styles.zone}>{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.zone' })}</div>
							<div className={styles.infoList}>
								<div className={styles.infoItem}>
									<span className={styles.infoNumbers}>+22</span>
									<span className={styles.diff}>
										{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.damageDiff' })}
									</span>
									<span className={styles.side}>
										{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.damageSide' })}
									</span>
								</div>

								<div className={styles.infoItem}>
									<span className={styles.infoNumbers}>-1.4</span>
									<span className={styles.diff}>{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.KDDiff' })}</span>
									<span className={styles.side}>{intl.formatMessage({ id: 'landing.afterGameTabs.tab2.KDSide' })}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Strengths;
