import React from 'react';
import styles from './buttons.module.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useIntl } from 'gatsby-plugin-react-intl';
import { sendEvent } from '@src/utils/amplitude';

const Buttons = ({ afterGameTab }) => {
	const intl = useIntl();
	return (
		<div className={styles.btnsWrapper}>
			<div className={styles.btnsWrapperText}>{intl.formatMessage({ id: 'landing.afterTitle' })}</div>
			<div className={styles.btns}>
				<AnchorLink
					href="#analizy"
					className={`btn btn--sm btn--gray ${styles.btn} ${afterGameTab === 1 ? ' active' : ''}`}
					onClick={() => {
						sendEvent('New Landing / After The Game / Change Screen', {
							State: 'Mistakes',
						});
					}}
				>
					{intl.formatMessage({ id: 'landing.afterGameBtns.btn1' })}
				</AnchorLink>
				<AnchorLink
					href="#strengths"
					className={`btn btn--sm btn--gray ${styles.btn} ${afterGameTab === 2 ? ' active' : ''}`}
					onClick={() => {
						sendEvent('New Landing / After The Game / Change Screen', {
							State: 'Economics Positioning',
						});
					}}
				>
					{intl.formatMessage({ id: 'landing.afterGameBtns.btn2' })}
				</AnchorLink>
				{/* <AnchorLink
					href="#strengths"
					className={`btn btn--sm btn--gray ${styles.btn} ${afterGameTab === 2 ? ' active' : ''}`}
					onClick={() => {
						sendEvent('New Landing / After The Game / Change Screen', {
							State: 'Positioning',
						});
					}}
				>
					{intl.formatMessage({ id: 'landing.afterGameBtns.btn3' })}
				</AnchorLink>
				<AnchorLink
					href="#strengths"
					className={`btn btn--sm btn--gray ${styles.btn} ${afterGameTab === 2 ? ' active' : ''}`}
					onClick={() => {
						sendEvent('New Landing / After The Game / Change Screen', {
							State: 'AIM',
						});
					}}
				>
					{intl.formatMessage({ id: 'landing.afterGameBtns.btn4' })}
				</AnchorLink> */}
			</div>
		</div>
	);
};

export default Buttons;
